import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainComponent } from '@app/main/main.component';

//Modules
import { ColorsRoutingModule } from '@app/shared/pages/colors/colors-routing.module';
import { KioskRoutingModule } from '@app/kiosk/kiosk-routing.module';

const routes: Routes = [
    {
        path: '',
        redirectTo: 'kiosk',
        pathMatch: 'full',
    },
    {
        path: '**',
        redirectTo: 'kiosk',
        pathMatch: 'full',
    },
    {
        path: '',
        component: MainComponent,
        children: [
            {
                path: 'kiosk',
                loadChildren: () =>
                    import('@app/kiosk/kiosk.module').then(
                        (m) => m.KioskModule
                    ),
            },
            {
                path: 'colors',
                loadChildren: () =>
                    import('@app/shared/pages/colors/colors.module').then(
                        (m) => m.ColorsModule
                    ),
            },
        ],
    },
];

@NgModule({
    imports: [
        RouterModule.forChild(routes),
        KioskRoutingModule,
        ColorsRoutingModule,
    ],
    exports: [RouterModule],
})
export class MainRoutingModule {}
